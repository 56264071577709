<template>
  <div class="main">
    <div class="" v-show="loading == 30">
      <div class="cert-head" v-show="item.cert == 'GIA'">
        <div class="items">
          <div class="title">{{item.cert}}证书编号</div>
          <div class="text">{{item.reportNo}}</div>
        </div>
        <div class="items">
          <div class="title">签发日期</div>
          <div class="text">{{item.certCreateTime}}</div>
        </div>
        <div href="javascript:void(0);" @click="getPDF(item.certFile)">
          <div class="items btn"><img v-show="btnLoad" src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/uniapp/static/btnLoading.png" alt=""> {{item.certFile == '' || item.certFile == 'null' ? '获取证书' : '证书查看'}}</div>
        </div>
      </div>
      <div class="cert-head" v-show="item.cert == 'IGI'">
        <div class="items">
          <div class="title">{{item.cert}}证书编号</div>
          <div class="text">{{item.reportNo}}</div>
        </div>
        <div class="items">
          <div class="title">报告地和日期</div>
          <div class="text">{{item.igiReportCityDate || '-'}}</div>
        </div>
        <a :href="item.certFile" target="_blank">
          <div class="items btn">证书查看</div>
        </a>
        <div class="items">
          <div class="title">描述</div>
          <div class="text">{{item.igiDescription}}</div>
        </div>
        <div class="items">
          <div class="title">形状和切工</div>
          <div class="text">{{item.igiShapeAndCut}}</div>
        </div>

      </div>
      <div class="cert-head" v-show="item.cert == 'HRD'">
        <div class="items">
          <div class="title">{{item.cert}}证书编号</div>
          <div class="text">{{item.reportNo}}</div>
        </div>
        <div class="items">
          <div class="title">证书类型</div>
          <div class="text">{{item.reportType}}</div>
        </div>
        <a :href="item.certFile" target="_blank">
          <div class="items btn">证书查看</div>
        </a>
        <div class="items">
          <div class="title">颁发日期</div>
          <div class="text">{{item.certCreateTime}}</div>
        </div>
      </div>

      <div class="items-main">
        <div class="title">钻石档案</div>

        <div class="item-main">
          <!--old-->
          <!--new-->
          <div class="left" v-show="item.cert == 'GIA'">
            <div class="title"><div class="text">Round Brilliant</div></div>
            <div class="items">
              <div class="item"><div>尺寸</div><div>{{item.m1 || '-'}}</div></div>
              <div class="item"><div>克拉重量</div><div>{{item.diaSize+'Carat' || '-'}}</div></div>
              <div class="item"><div>颜色等级</div><div>{{item.color || '-'}}</div></div>
              <div class="item"><div>净度等级</div><div>{{item.clarity || '-'}}</div></div>
              <div class="item"><div>切工等级</div><div>{{item.cut || '-'}}</div></div>
            </div>
            <div class="title"><div class="text">Proportions</div></div>
            <div class="items">
              <div class="item"><div>全深比</div><div>{{item.totalDepth || '-'}}</div></div>
              <div class="item"><div>台宽比</div><div>{{item.tableSize || '-'}}</div></div>
              <div class="item"><div>冠角</div><div>{{item.crownAngle || '-'}}</div></div>
              <div class="item"><div>冠高比</div><div>{{item.crownHeight || '-'}}</div></div>
              <div class="item"><div>亭角</div><div>{{item.pavilionAngle || '-'}}</div></div>
              <div class="item"><div>亭深比</div><div>{{item.pavilionDepth || '-'}}</div></div>
              <div class="item"><div>星小面比</div><div>{{item.starFacetRatio || '-'}}</div></div>
              <div class="item"><div>下腰小面比</div><div>{{item.lowerWaistFacetRatio || '-'}}</div></div>
              <div class="item"><div>腰厚</div><div>{{item.waistThickness || '-'}}</div></div>
              <div class="item"><div>底尖</div><div>{{item.bottomTip || '-'}}</div></div>
            </div>
            <div class="title"><div class="text">Finish</div></div>
            <div class="items">
              <div class="item"><div>抛光</div><div>{{item.polish || '-'}}</div></div>
              <div class="item"><div>对称性</div><div>{{item.sym || '-'}}</div></div>
            </div>
            <div class="title"><div class="text">Fluorescence</div></div>
            <div class="items">
              <div class="item"><div>荧光</div><div>{{item.flour || '-'}}</div></div>
            </div>
            <div class="title"><div class="text">Clarity CharacteristicsS</div></div>
            <div class="items">
              <div class="item"><div>净度特征</div>{{chinese || '-'}}</div>
            </div>
            <div class="title"><div class="text">Inscription(s)</div></div>
            <div class="items">
              <div class="item"><div>{{item.inscription || '-'}}</div></div>
            </div>
            <div class="title"><div class="text">Comments</div></div>
            <div class="items">
              <div class="item"><div>备注</div><div>{{item.comment || '-'}}</div></div>
            </div>
          </div>

          <div class="left" v-show="item.cert == 'IGI'">
            <div class="title"><div class="text">Grading Results</div></div>
            <div class="items">
              <div class="item"><div>重量</div><div>{{item.diaSize+'Carat' || '-'}}</div></div>
              <div class="item"><div>颜色</div><div>{{item.color || '-'}}</div></div>
              <div class="item"><div>净度</div><div>{{item.clarity || '-'}}</div></div>
              <div class="item"><div>切工</div><div>{{item.cut || '-'}}</div></div>
            </div>
            <div class="title"><div class="text">Additional Grading Information</div></div>
            <div class="items">
              <div class="item"><div>抛光</div><div>{{item.polish || '-'}}</div></div>
              <div class="item"><div>对称</div><div>{{item.sym || '-'}}</div></div>
              <div class="item"><div>尺寸</div><div>{{item.m1 || '-'}}</div></div>
              <div class="item"><div>台宽比</div><div>{{item.tableSize || '-'}}</div></div>
              <div class="item"><div>冠高比</div><div>{{item.crownHeight || '-'}}</div></div>
              <div class="item"><div>亭深比</div><div>{{item.totalDepth || '-'}}</div></div>
              <div class="item"><div>腰厚</div><div>{{item.waistThickness || '-'}}</div></div>
              <div class="item"><div>底尖</div><div>{{item.girdle || '-'}}</div></div>
              <div class="item"><div>全深比</div><div>{{item.totalDepth || '-'}}</div></div>
              <div class="item"><div>荧光</div><div>{{item.flour || '-'}}</div></div>
              <div class="item"><div>腰码</div><div>{{item.inscription || '-'}}</div></div>
            </div>
          </div>

          <div class="left" v-show="item.cert == 'HRD'">
            <div class="title"><div class="text">Results</div></div>
            <div class="items">
              <div class="item"><div>形状</div><div>{{item.shape || '-'}}</div></div>
              <div class="item"><div>重量</div><div>{{item.diaSize || '-'}}</div></div>
              <div class="item"><div>颜色</div><div>{{item.color || '-'}}</div></div>
              <div class="item"><div>净度</div><div>{{item.clarity || '-'}}</div></div>
              <div class="item"><div>切工</div><div>{{item.cut || '-'}}</div></div>
              <div class="item"><div>比例</div><div>{{item.proportions || '-'}}</div></div>
              <div class="item"><div>抛光</div><div>{{item.polish || '-'}}</div></div>
            </div>
            <div class="title"><div class="text">Additional Information</div></div>
            <div class="items">
              <div class="item"><div>荧光</div><div>{{item.flour || '-'}}</div></div>
              <div class="item"><div>尺寸</div><div>{{item.m1 || '-'}}</div></div>
              <div class="item"><div>腰棱</div><div>{{item.girdle || '-'}}</div></div>
              <div class="item"><div>底尖</div><div>{{item.bottomTip || '-'}}</div></div>
              <div class="item"><div>全深比</div><div>{{item.totalDepth || '-'}}</div></div>
              <div class="item"><div>台宽比</div><div>{{item.tableSize || '-'}}</div></div>
              <div class="item"><div>冠高比</div><div>{{item.crownHeight || '-'}}</div></div>
              <div class="item"><div>亭深比</div><div>{{item.pavilionDepth || '-'}}</div></div>
              <div class="item"><div>冠部上腰面水平投影长度比</div><div>{{item.halvesCrown || '-'}}</div></div>
              <div class="item"><div>亭部下腰面水平投影长度比</div><div>{{item.halvesPavilion || '-'}}</div></div>
              <div class="item"><div>α & β之和</div><div>{{item.hrdSum || '-'}}</div></div>
              <div class="item"><div>腰码</div><div>{{item.inscription || '-'}}</div></div>
              <div class="item"><div>备注</div><div>{{item.comment || '-'}}</div></div>
            </div>
          </div>
          <div class="right">
            <div class="title">验证其他报告</div>
            <div class="border"></div>
            <div class="input">
              <input type="text" placeholder="输入报告编码" ref="input">
              <div class="btn" @click="certSearch">查询</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nothing" v-show="loading == 10">
      <loading></loading>
    </div>

    <div class="nothing" v-show="loading == 20">
      <div class="icon">
        <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/certno.png" alt="">
        <div class="text">没有找到相关证书信息！</div>
      </div>
    </div>

    <div style="display: none">{{shopInit}}</div>
  </div>
</template>

<script>
  import loading from '../../components/loading/loading'
  import Cert from '@/api/cert'
  import newRequest from '@/api/request'
  export default {
    name: 'certDetails',
    data() {
      return {
        cert: '',
        item: '',
        chinese:'',
        loading: 10,
        shop: '',
        relationId: '',
        btnLoad: false
      }
    },
    components: {
      loading
    },
    mounted () {
      this.cert = this.$route.query.certNo
      this.getItem(this.cert)
    },
    created () {
    },
    computed: {
      shopInit: function () {
        let shop = this.$store.state.shop
        let relationId = ''
        if (shop && this.$store.state.SequenceNo) {
          relationId = shop.relationId
        }
        return relationId
      }
    },
    watch: {
      $route(to, from) {
        console.log(to)
        if (to.query.certNo == this.cert) return
        this.cert = to.query.certNo
        this.getItem(to.query.certNo)
      },
      shopInit: {
        handler (newValue, oldValue) {
          if (this.relationId != '' || newValue == '') return
          this.relationId = newValue
          if (newValue != '') {
            let shop = this.$store.state.shop
            this.shop = shop
            // this.$getif(shop.vipType)
            // this.getInit()
          }
        },
        immediate: true,
      },
    },
    methods: {
      getItem(code) {
        // 6375239290
        let params = {
          CertNo : code,
          Source: 1
        }

        Cert.findcert(params).then(res => {
          console.log(res.status)
          console.log(res.status == '200')
          if (res.status == '200') {
            this.item = res.result
            this.loading = 30
            if (res.result.cert == 'GIA') {
              if (res.result.clarityCharacteristics || res.result.clarityCharacteristics != '' || res.result.clarityCharacteristics != '-') {
                this.chinese = this.$getChinese(res.result.clarityCharacteristics)
              }
            }
          }else{
            this.loading = 20
          }
        }).catch(error => {
          this.loading = 20
          // this.loading = 20
          console.log('error', error)
        })
      },
      getPDF(url) {
        if (url != '' && url != 'null') {
          window.open(url)
        }else{
          this.getNewPDF()
        }
      },
      getNewPDF() {

        this.btnLoad = true
        let accountId = this.shop.accountId
        let url = newRequest.GetCertFile;
        let params = {
          certNo: this.cert,
          accountId: accountId
        }

        this.$http.get(url, {params}).then(res => {
          console.log(res)
          this.btnLoad = false
          if (res.data.Code == 1) {
            if (res.data.Result.UpdateCertFileSuccessState == 1) {
              this.item.certFile = res.data.Result.CertFile
            } else {
              this.item.certFile = res.data.Result.CertFile
            }
          } else {
            this.item.certFile = ''
          }
        }).catch(err => {
          console.log(err)
          this.item.certFile = ''
        })
      },
      certSearch() {
        let certNo = this.$refs.input.value
        if (certNo == '') {
          this.$message.error('证书号不能为空');
          return
        }
        if (!(/^[0-9]{8,12}$/.test(certNo))) {
          this.$message.error('请填写正确的证书号');
          return
        }

        window.open(`/certDetails?certNo=${certNo}`)
        // this.$router.push({path: `/certDetails?certNo=${certNo}`})
      }
    }
  }
</script>

<style scoped lang="scss">
  .loadings{

  }

  .cert-head {
    width: 100%;
    padding: 48px 0 40px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    border-bottom: 1px solid #D9D9D9;

    a{
      margin-left: auto;
      text-decoration: none;
    }

    .items {
      min-width: 33.33%;
      color: #333333;
      .title {
        font-size: 17px;
        font-weight: 600;
        margin: 12px 0;
      }

      .text{
        font-size: 30px;
      }

      &.btn {
        line-height: 78px;
        width: 223px;
        height: 78px;
        background: #FF8178;
        color: #FFFFFF;
        font-size: 17px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
          width: 19px;
          height: 19px;
          margin-right: 20px;
          animation: myRotate 1.9s linear infinite;
        }
      }
    }
  }

  .items-main{
    padding: 70px 0 80px;
    color: #333333;

    .title{
      font-size: 25px;
      font-weight: 600;
    }

    .item-main{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .left{
        padding-right: 25px;
        border-right: 1px solid #D9D9D9;

        .title{
          font-size: 17px;
          margin-top: 40px;
          margin-bottom: 20px;
        }

        .items{
          width: 850px;
          font-size: 14px;
          .item{
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;

            &.item:nth-child(odd) {
              background: #FBECE0;
            }
          }
        }
      }

      .right{
        width: 100%;
        text-align: center;

        .title{
          font-size: 18px;
        }

        .border{
          width: 60px;
          height: 3px;
          background: #F5B5A9;
          margin: 8px auto 20px;
        }

        .input{
          display: flex;
          justify-content: flex-end;
          align-items: center;

          input{
            height: 40px;
            width: 230px;
            border: 1px solid #CCCCCC;
            padding-left: 20px;
          }

          .btn{
            padding: 0 15px;
            height: 40px;
            background: #FF8178;
            color: #FFFFFF;
            margin-left: 10px;
            line-height: 40px;
          }
        }
      }
    }
  }

  .nothing{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 512px;

    .icon{
      width: 230px;
      position: relative;

      img{
        width: 100%;
      }

      .text{
        width: 100%;
        position: absolute;
        bottom: -41px;
        text-align: center;
        font-size: 16px;
        color: #666666;
      }
    }
  }

  @keyframes myRotate {
    0% {
      -webkit-transform: rotate(0deg);
    }

    50% {
      -webkit-transform: rotate(180deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }
</style>
