import {POST, GET} from './index'

// api/CertQueryRecord/findcert GIA证书查询
function findcert (params) {
  return GET('/api/CertQueryRecord/findcert',params)
}

export default {
  findcert
}
