<template>
    <div class="loading">
      <div class="typing_loader"></div>
    </div>
</template>

<script>
  export default {
    name: 'loading'
  }
</script>

<style scoped lang="scss">
  .loading {
    display: flex;
    height: 72px;
    width: 100%;
    z-index: 99999;
    justify-content: center;
    align-items: center;
    color: #F5B5A9;

    &.fixed{
      position: fixed;
      left: calc(50% - 100px);
      top: calc(50% - 100px);
      width: 200px;
      height: 200px;
      background-color: rgba(0,0,0,.5);
      border-radius: 20px;
    }

    .line-scale {
      display: flex;
      align-items: center;
    }

    .line-scale>.lab {
      background:linear-gradient(270deg, #FCA5B9 0%, #F5B5A9 100%);
      width: 8px;
      height: 60px;
      border-radius: 8px;
      margin: 8px;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      display: inline-block;
    }

    .load-title {
      margin-left: 40px;
    }
  }

  .typing_loader{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    -webkit-animation: colorblock 1s linear infinite alternate;
    -moz-animation: colorblock 1s linear infinite alternate;
    animation: colorblock 1s linear infinite alternate;
    margin-right: 20px;
  }


  @-webkit-keyframes colorblock{
    0%{
      background-color: rgba(245,181,169, 1);
      // opacity: 1;
      box-shadow: 24px 0px 0px 0px rgba(245,181,169,0.2),
      48px 0px 0px 0px rgba(245,181,169,0.2);
    }
    25%{
      background-color: rgba(245,181,169, 0.4);
      // opacity: 0.4;
      box-shadow: 24px 0px 0px 0px rgba(245,181,169,2),
      48px 0px 0px 0px rgba(245,181,169,0.2);
    }
    75%{
      background-color: rgba(245,181,169, 0.4);
      // opacity: 0.4;
      box-shadow: 24px 0px 0px 0px rgba(245,181,169,0.2),
      48px 0px 0px 0px rgba(245,181,169,1);
    }
  }

  @keyframes line-scale {
    0% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
    }

    50% {
      -webkit-transform: scaley(0.4);
      transform: scaley(0.4);
    }

    100% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
    }
  }

  .line-scale>.lab:nth-child(1) {
    -webkit-animation: line-scale 1s -0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: line-scale 1s -0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  .line-scale>.lab:nth-child(2) {
    -webkit-animation: line-scale 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: line-scale 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  .line-scale>.lab:nth-child(3) {
    -webkit-animation: line-scale 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: line-scale 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  .line-scale>.lab:nth-child(4) {
    -webkit-animation: line-scale 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: line-scale 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  .line-scale>.lab:nth-child(5) {
    -webkit-animation: line-scale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: line-scale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }
</style>
